<template>
  <v-dialog v-model="dialogFLaunch" width="400">
    <v-card>
      <v-toolbar class="primary">
        <v-toolbar-title class="white--text">
          {{ $t("titleVue.clientManagement.dialogFlowLaunch") }}
        </v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn
          small
          fab
          icon
          class="white--text"
          @click.native="dialogFLaunch = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="validForm">
          <v-row>
            <v-col cols="12">
              <v-radio-group
                v-model="formData.flow"
                row
                :rules="[rules.required]"
              >
                <v-radio
                  :label="$t('form.clientManagement.dialogFlowLaunch.realFlow')"
                  :value="1"
                ></v-radio>
                <v-radio
                  :label="
                    $t('form.clientManagement.dialogFlowLaunch.controlFlow')
                  "
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :value="dateFormated"
                    :label="$t('form.clientManagement.dialogFlowLaunch.date')"
                    persistent-hint
                    dense
                    :rules="[rules.required]"
                    prepend-icon="mdi-calendar"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.date"
                  first-day-of-week="1"
                  no-title
                  @input="datePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialogFLaunch = false" color="error">
          {{ $t("button.close") }}
        </v-btn>
        <v-btn @click="startLaunch" :disabled="!validForm" color="info">
          {{ $t("button.validate") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ClientCuisineEdit from "@/components/clientCuisineEdit";
export default {
  created() {},
  props: {
    dialog: { type: Boolean, required: true },
    idClient: { required: true, type: Number }
  },

  data() {
    return {
      validForm: false,
      rules: {
        required: v => !!v || this.$t("rules.required")
      },
      datePicker: false,
      formData: {
        flow: 0,
        date: ""
      }
    };
  },
  computed: {
    ...mapState("clients", ["clientParams"]),
    dialogFLaunch: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      }
    },
    dateFormated() {
      if (!this.formData.date) return "";
      return this.$d(this.$root.$newDate(this.formData.date), "date");
    }
  },
  methods: {
    ...mapActions("clients", ["LaunchManualFlow"]),
    startLaunch() {
      if (this.formData.flow == 1) {
        //Flux réel
        this.$root
          .$confirm(
            this.$t("titleVue.clientManagement.dialogFlowLaunch"),
            this.$t("sureFlow"),
            {
              color: "error",
              width: 400
            }
          )
          .then(confirm => {
            if (confirm) {
              this.launchManual();
            }
          });
      } else {
        this.launchManual();
      }
    },
    launchManual() {
      this.$root.$overlay.show();
      this.LaunchManualFlow({
        clientId: this.idClient,
        flowType: this.formData.flow,
        dateConsomation: this.formData.date
      })
        .then(res => {
          this.$root.$snackBar(this.$t("success.flowLaunch"));
          this.$root.$overlay.hide();
        })
        .catch(res => {
          this.$root.$snackBar(this.$t("errors.flowLaunch"), {
            color: "error"
          });
          this.$root.$overlay.hide();
        });
    }
  },
  components: {}
};
</script>
