<template>
  <v-dialog v-model="dialogCuisineEdit" width="500">
    <v-card>
      <v-toolbar class="primary">
        <v-toolbar-title class="white--text">
          <span v-if="isUpdate">
            {{ $t("titleVue.clientManagement.dialogCuisine") }}
          </span>
          <span v-else>
            {{ $t("titleVue.clientManagement.dialogCuisineCreate") }}
          </span>
        </v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn
          small
          fab
          icon
          class="white--text"
          @click.native="dialogCuisineEdit = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="formData.codeSite"
                :rules="rules.codeSite"
                :label="$t('form.clientManagement.clientCuisineEdit.siteCode')"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="formData.libelleCuisine"
                :rules="rules.name"
                :label="$t('form.clientManagement.clientCuisineEdit.name')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="formData.emailCuisine"
                :rules="rules.email"
                :label="$t('form.clientManagement.clientCuisineEdit.email')"
                :placeholder="
                  $t('form.clientManagement.clientCuisineEdit.emailPH')
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="formData.urlCuisine"
                :rules="rules.url"
                :label="$t('form.clientManagement.clientCuisineEdit.url')"
                :placeholder="
                  $t('form.clientManagement.clientCuisineEdit.urlPH')
                "
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="formData.codeFusionId"
                :rules="rules.numeric"
                :label="$t('form.clientManagement.clientEdit.fusionCode')"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="formData.retentionDuration"
                :rules="rules.numeric"
                :label="
                  $t(
                    'form.clientManagement.clientCuisineEdit.retentionDuration'
                  )
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-checkbox
                :label="
                  $t(
                    'form.clientManagement.clientCuisineEdit.chkremarkableProduct'
                  )
                "
                v-model="formData.produitRemarquables"
                class="ma-0"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-checkbox
                :label="$t('form.clientManagement.clientCuisineEdit.chklabels')"
                v-model="formData.etiquettes"
                class="ma-0"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-checkbox
                :label="
                  $t(
                    'form.clientManagement.clientCuisineEdit.chkTechnicalSheet'
                  )
                "
                v-model="formData.fichesTechniques"
                class="mt-0 mb-4"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!valid" @click="save" color="info">
          {{ $t("button.save") }}
        </v-btn>
        <v-btn @click="dialogCuisineEdit = false" color="error">
          {{ $t("button.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    dialog: { type: Boolean, required: true },
    idClientParam: { required: true, type: Number },
    idClient: { required: true, type: Number }
  },
  watch: {
    valid() {
      this.$refs.form.resetValidation();
    },
    clientParamEdited(val) {
      this.formData = val;
    }
  },
  data() {
    return {
      valid: true,
      formData: {
        codeSite: "",
        urlCuisine: "",
        libelleCuisine: "",
        emailCuisine: "",
        codeFusionId: "",
        retentionDuration: "",
        produitRemarquables: false,
        etiquettes: false,
        fichesTechniques: false
      },
      rules: {
        codeSite: [
          v => !!v || this.$t("rules.fieldNoEmpty"),
          v => (v && v.length == 8) || this.$t("rules.invalidFormat")
        ],
        numeric: [
          v => !!v || this.$t("rules.fieldNoEmpty"),
          v => Number.isInteger(v * 1) || this.$t("rules.numericOnly")
        ],
        email: [
          v => !!v || this.$t("rules.fieldNoEmpty"),
          v => {
            const pattern = /^(\s?(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})),)*(\s?(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+)$/;
            return pattern.test(v) || this.$t("rules.invalidMail");
          },
          v => v.length <= 255 || this.$t("rules.characterMax", { value: 255 })
        ],
        name: [
          v => !!v || this.$t("rules.fieldNoEmpty"),
          v =>
            (v.length <= 150 && v.length > 0) ||
            this.$t("rules.characterMax", { value: 150 })
        ],
        url: [
          v => !!v || this.$t("rules.fieldNoEmpty"),
          v => {
            const pattern = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]):([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
            return pattern.test(v) || this.$t("rules.invalidFormat");
          }
        ]
      }

      // urlRules: [
      //   v => !v || this.$t("rules.invalidFormat") //FIXME format à voir
      // ]
    };
  },
  computed: {
    ...mapState("clients", ["clientParamEdited"]),
    dialogCuisineEdit: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      }
    },
    isUpdate() {
      return this.idClientParam != 0;
    }
  },
  methods: {
    ...mapActions("clients", [
      "fetchClientParam",
      "updateClientParam",
      "createClientParam"
    ]),
    save() {
      if (this.isUpdate) {
        this.updateClientParam({
          id: this.formData.id,
          codeSite: this.formData.codeSite,
          urlCuisine: this.formData.urlCuisine,
          libellecuisine: this.formData.libelleCuisine,
          mailCuisine: this.formData.emailCuisine,
          retentionDuration: this.formData.retentionDuration,
          codeFusionId: this.formData.codeFusionId,
          produitRemarquables: this.formData.produitRemarquables,
          etiquettes: this.formData.etiquettes,
          fichesTechniques: this.formData.fichesTechniques
        })
          .then(data => {
            this.$root.$snackBar(this.$t("success.clientEdit"));
            this.dialogCuisineEdit = false;
            this.$emit("search");
          })
          .catch(res => {
            this.$root.$snackBar(this.$t("errors.clientEdit"), {
              color: "error"
            });
          });
      } else {
        this.createClientParam({
          clientId: this.idClient,
          codeSite: this.formData.codeSite,
          urlCuisine: this.formData.urlCuisine,
          libellecuisine: this.formData.libelleCuisine,
          mailCuisine: this.formData.emailCuisine,
          retentionDuration: this.formData.retentionDuration,
          codeFusionId: this.formData.codeFusionId,
          produitRemarquables: this.formData.produitRemarquables,
          etiquettes: this.formData.etiquettes,
          fichesTechniques: this.formData.fichesTechniques
        })
          .then(data => {
            this.$root.$snackBar(this.$t("success.clientEdit"));
            this.dialogCuisineEdit = false;
            this.$emit("search");
          })
          .catch(res => {
            this.$root.$snackBar(this.$t("errors.clientEdit"), {
              color: "error"
            });
          });
      }
    }
  },
  created() {
    if (this.isUpdate) {
      this.fetchClientParam({
        nIdClientParams: this.idClientParam
      }).catch(res => {
        this.$root.$snackBar(this.$t("errors.unknow"), {
          color: "error"
        });
      });
    }
  }
};
</script>
