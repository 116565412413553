<template>
  <v-dialog v-model="dialogCuisine" width="1100">
    <v-card>
      <v-toolbar class="primary">
        <v-toolbar-title class="white--text">
          {{ $t("titleVue.clientManagement.dialogSetting") }}
        </v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn
          small
          fab
          icon
          class="white--text"
          @click.native="dialogCuisine = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- Tableau -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :loading="loading"
              :items="clientParams"
              hide-default-footer
              class="elevation-1 mb-3"
            >
              <template #[`item.produitRemarquables`]="{ item }">
                <span v-if="item.produitRemarquables">{{ $t("yes") }}</span>
              </template>
              <template #[`item.etiquettes`]="{ item }">
                <span v-if="item.etiquettes">{{ $t("yes") }}</span>
              </template>
              <template #[`item.fichesTechniques`]="{ item }">
                <span v-if="item.fichesTechniques">{{ $t("yes") }}</span>
              </template>
              <template #[`item.action`]="{ item }">
                <v-btn
                  small
                  icon
                  fab
                  class="px-2 blue--text"
                  :title="$t('titleVue.clientManagement.dialogCuisine')"
                  @click.native="edit(item.id)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  small
                  icon
                  fab
                  class="px-2 red--text"
                  :title="$tc('titleVue.clientManagement.dialogDelete', 2)"
                  @click.native="delClientCuisine(item.id)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <template slot="no-data">
                <v-alert :value="true" color="error" icon="mdi-alert-outline">
                  {{ $t("noData") }}
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
          <!-- Edition du paramétrage d'une cuisine -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="add" color="info">
          {{ $t("button.add") }}
        </v-btn>
        <v-btn @click="dialogCuisine = false" color="error">
          {{ $t("button.close") }}
        </v-btn>
      </v-card-actions>
      <clientCuisineEdit
        v-if="dialogCuisineEdit"
        :dialog.sync="dialogCuisineEdit"
        :idClientParam="idClientParam"
        :idClient="idClient"
        @search="search()"
      ></clientCuisineEdit>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ClientCuisineEdit from "@/components/clientCuisineEdit";
export default {
  created() {
    this.search();
  },
  props: {
    dialog: { type: Boolean, required: true },
    idClient: { required: true, type: Number }
  },

  data() {
    return {
      headers: [
        {
          text: this.$t("headers.clientManagement.clientCuisine.kitchenCode"),
          value: "codeSite"
        },
        {
          text: this.$t("headers.clientManagement.clientCuisine.kitchenUrl"),
          value: "urlCuisine"
        },
        {
          text: this.$t("headers.clientManagement.clientManagement.fusionCode"),
          value: "codeFusionId"
        },
        {
          text: this.$t(
            "headers.clientManagement.clientCuisine.remarkableProduct"
          ),
          value: "produitRemarquables"
        },
        {
          text: this.$t("headers.clientManagement.clientCuisine.labels"),
          value: "etiquettes"
        },
        {
          text: this.$t(
            "headers.clientManagement.clientCuisine.technicalSheet"
          ),
          value: "fichesTechniques"
        },
        { text: "", value: "action", sortable: false }
      ],
      idClientParam: 0,
      dialogCuisineEdit: false,
      loading: false
    };
  },
  computed: {
    ...mapState("clients", ["clientParams"]),
    dialogCuisine: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      }
    }
  },
  methods: {
    ...mapActions("clients", ["fetchClientParams", "deleteClientParam"]),
    search() {
      this.loading = true;
      this.fetchClientParams({
        nIdClient: this.idClient
      })
        .then(data => {
          this.loading = false;
        })
        .catch(res => {
          this.loading = false;
          this.$root.$snackBar(this.$t("errors.unknow"), {
            color: "error"
          });
        });
    },
    add() {
      this.idClientParam = 0;
      this.dialogCuisineEdit = true;
    },
    edit(idClientParam) {
      this.idClientParam = idClientParam;
      this.dialogCuisineEdit = true;
    },
    closeClientCuisineEdit() {
      this.dialogCuisineEdit = false;
      this.search();
    },
    delClientCuisine(id) {
      this.$root
        .$confirm(
          this.$tc("titleVue.clientManagement.dialogDelete", 2),
          this.$tc("sureClient", 2),
          {
            color: "error",
            width: 400
          }
        )
        .then(confirm => {
          if (confirm) {
            this.deleteClientParam({
              nIdClientParam: id
            })
              .then(data => {
                this.$root.$snackBar(this.$t("success.delete"));
                this.search();
              })
              .catch(res => {
                this.$root.$snackBar(this.$t("errors.delete"), {
                  color: "error"
                });
              });
          }
        });
    }
  },
  components: {
    ClientCuisineEdit
  }
};
</script>
