<template>
  <div>
    <!-- Formulaire -->
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="searchField"
          :label="$t('form.clientManagement.clientManagement.search')"
          :placeholder="$t('search')"
          solo
          clearable
          append-icon="mdi-magnify"
          @click:append="search"
          v-on:keyup.enter="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Tableau -->
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="clients"
          class="elevation-1"
          :item-class="color"
        >
          <template #[`item.action`]="{ item }">
            <v-btn
              flex
              small
              icon
              fab
              class="px-2 blue--text"
              :disabled="!item.actif"
              :title="$t('titleVue.clientManagement.dialogFlowLaunch')"
              @click.native="launchFlow(item.id)"
            >
              <v-icon>mdi-restart</v-icon>
            </v-btn>
            <v-btn
              flex
              small
              icon
              fab
              class="px-2 blue--text"
              :title="$t('titleVue.clientManagement.dialogChange')"
              @click.native="editClient(item.id)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn
              flex
              small
              icon
              fab
              class="px-2 blue--text"
              :title="$t('titleVue.clientManagement.dialogSetting')"
              @click.native="clientCuisine(item.id)"
            >
              <v-icon>mdi-wrench-outline</v-icon>
            </v-btn>
            <v-btn
              flex
              small
              icon
              fab
              class="px-2 red--text"
              :title="$tc('titleVue.clientManagement.dialogDelete', 1)"
              @click.native="delClient(item.id)"
            >
              <v-icon>mdi-delete-forever-outline</v-icon>
            </v-btn>
          </template>
          <template slot="no-data">
            <v-alert :value="true" color="error" icon="mdi-alert-outline">
              {{ $t("noData") }}
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-btn class="mt-4" @click="addClient" color="info">
          {{ $t("button.add") }}
        </v-btn>
      </v-col>
    </v-row>
    <clientEdit
      v-if="dialog"
      :dialog.sync="dialog"
      :idClient="idClient"
      @search="search()"
    ></clientEdit>
    <clientCuisine
      v-if="dialogCuisine"
      :dialog.sync="dialogCuisine"
      :idClient="idClient"
      @close="closeClientCuisine()"
    ></clientCuisine>
    <DialogFlowLaunch
      v-if="dialogFlowLaunch"
      :dialog.sync="dialogFlowLaunch"
      :idClient="idClient"
    ></DialogFlowLaunch>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ClientEdit from "@/components/clientEdit";
import ClientCuisine from "@/components/clientCuisine";
import DialogFlowLaunch from "@/components/DialogFlowLaunch";
export default {
  created() {
    this.search();
  },

  data() {
    return {
      headers: [
        {
          text: this.$t("headers.clientManagement.clientManagement.clientName"),
          align: "left",
          value: "libelle"
        },
        {
          text: this.$t("headers.clientManagement.clientManagement.site"),
          value: "codeSite"
        },
        { text: "", value: "action", sortable: false, width: "220px" }
      ],
      searchField: "",
      dialog: false,
      dialogCuisine: false,
      dialogFlowLaunch: false,
      loading: false,
      idClient: 0
    };
  },
  computed: {
    ...mapState("clients", ["clients"])
  },
  methods: {
    ...mapActions("clients", ["fetchClients", "deleteClient"]),
    search() {
      this.loading = true;
      this.fetchClients({ sSearch: this.searchField })
        .then(data => {
          this.loading = false;
        })
        .catch(res => {
          this.loading = false;
          this.$root.$snackBar(this.$t("errors.unknow"), {
            color: "error"
          });
        });
    },
    color(item) {
      if (!item.actif) return "red--text";
      return "black--text";
    },
    launchFlow(idClient) {
      this.idClient = idClient;
      this.dialogFlowLaunch = true;
    },
    addClient() {
      this.idClient = 0;
      this.dialog = true;
    },
    editClient(idClient) {
      this.idClient = idClient;
      this.dialog = true;
    },
    delClient(idClient) {
      this.$root
        .$confirm(
          this.$tc("titleVue.clientManagement.dialogDelete", 1),
          this.$tc("sureClient", 1),
          {
            color: "error",
            width: 400
          }
        )
        .then(confirm => {
          if (confirm) {
            this.deleteClient({ nIdClient: idClient })
              .then(res => {
                this.$root.$snackBar(this.$t("success.delete"));
                this.loading = true;
                this.search();
              })
              .catch(res => {
                this.$root.$snackBar(this.$t("errors.delete"), {
                  color: "error"
                });
              });
          }
        });
    },
    clientCuisine(idClient) {
      this.idClient = idClient;
      this.dialogCuisine = true;
    },
    closeClientCuisine() {
      this.dialogCuisine = false;
    }
  },
  components: {
    ClientEdit,
    ClientCuisine,
    DialogFlowLaunch
  }
};
</script>
